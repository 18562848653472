
// Optional. When using fullPage extensions
import fullpage from './vendor/fullPage.js-4.0.19/dist/fullpage.extensions.min';
// eslint-disable-next-line no-unused-vars
import scrollHorizontally from './vendor/fullPage.js-4.0.19/extensions/fullpage.scrollHorizontally.min';
import { isRTL } from "bootstrap/js/src/util"
// import('./vendor/fullPage.js-4.0.19/extensions/fullpage.cards.min');

// page is fully loaded
window.addEventListener("load",  function () {

    let pageHeader = document.querySelector('header');

    // eslint-disable-next-line camelcase
    let fullpage_api = new fullpage('#fullPage', {
        // Navigation
        menu: '#main-menu',
        lockAnchors: false,
        anchors: ['home', 'about', 'partners', 'contact'],
        navigation: true,
        navigationPosition: isRTL() ? 'left' : 'right',
        navigationTooltips: isRTL() ? ['الرئيسية', 'عن الشركة', 'شركاء النجاح', 'اتصل بنا'] : ['Home', 'About', 'Partners', 'Contact'],
        showActiveTooltip: false,
        slidesNavigation: true,
        slidesNavPosition: 'bottom',

        licenseKey: '77D14527-65F84765-8BF82EDE-2814ECA6',
        //sectionsColor: ['#ff73a1', '#4BBFC3', '#7BAABE', 'whitesmoke', '#ccddff', '#ccc'],

        // Scrolling
        css3: true,
        scrollingSpeed: 700,
        autoScrolling: true,
        fitToSection: true,
        fitToSectionDelay: 600,
        scrollBar: false,
        easing: 'easeInOutCubic',
        easingcss3: 'ease-in-out',
        loopBottom: false,
        loopTop: false,
        loopHorizontal: false,
        // continuousVertical: false,
        // continuousHorizontal: false,
        scrollHorizontally: true,
        scrollHorizontallyKey: 'cmJoLmNvbS5zYV90V1FjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDU1ZnA=',
        // interlockedSlides: false,
        //dragAndMove: 'fingersonly',
        // offsetSections: false,
        // resetSliders: false,
        // fadingEffect: false,
        normalScrollElements: '#element1, .element2',
        scrollOverflow: false,
        // scrollOverflowMacStyle: true,
        // scrollOverflowReset: 'slides',
        touchSensitivity: 15,
        bigSectionsDestination: null,

        // Accessibility
        // keyboardScrolling: true,
        // animateAnchor: true,
        // recordHistory: true,

        // Design
        controlArrows: false,
        // controlArrowsHTML: [
        //     '<div class="fp-arrow"></div>',
        //     '<div class="fp-arrow"></div>'
        // ],
        verticalCentered: true,
        // sectionsColor : ['#ccc', '#fff'],
        //paddingTop: '3em',
        //paddingBottom: '45px',
        // responsiveWidth: 767,
        // responsiveHeight: 747,
        // responsiveSlides: true,
        // parallax: false,
        // parallaxOptions: {type: 'reveal', percentage: 62, property: 'translate'},
        // dropEffect: false,
        // dropEffectOptions: { speed: 2300, color: '#F82F4D', zIndex: 9999},
        // waterEffect: false,
        // waterEffectOptions: { animateContent: true, animateOnMouseMove: true},
        // cards: false,
        // cardsKey:"cmJoLmNvbS5zYV9BNjBZMkZ5WkhNPXJiNg==",
        // cardsOptions: {perspective: 100, fadeContent: true, fadeBackground: true},

        // Custom selectors
        sectionSelector: '.section',
        slideSelector: '.slide',

        lazyLoading: false,
        observer: true,
        credits: false,

        // Events //

        // eslint-disable-next-line no-unused-vars
        //beforeLeave: function(origin, destination, direction, trigger){},

        // eslint-disable-next-line no-unused-vars
        onLeave: function(origin, destination, direction, trigger){
            if (pageHeader !== null) {
                if (direction === 'down') { // hide
                    if (!pageHeader.classList.contains('hide-header')) {
                        pageHeader.classList.add('hide-header');
                    }
                } else if (direction === 'up') { // show
                    if (pageHeader.classList.contains('hide-header')) {
                        pageHeader.classList.remove('hide-header');
                    }
                }
            }
        },

        // eslint-disable-next-line no-unused-vars
        afterLoad: function(origin, destination, direction, trigger){
            // Behavior when a full page is leaved
            // after leaving section 1
            // console.log(origin.index);
            // if (destination.index > 0) {
            //     // eslint-disable-next-line no-console
            //
            //     if (!document.body.classList.contains('scrolled')) {
            //         document.body.classList.add('scrolled');
            //     }
            // } else {
            //     if (document.body.classList.contains('scrolled')) {
            //         document.body.classList.remove('scrolled');
            //     }
            // }

            // Change Elements color based on Section color
            let mainBody = document.querySelector('body');

            if (destination.item.classList.contains('content-white')) {
                mainBody.classList.remove('content-dark');
                mainBody.classList.remove('content-white');
                //fpNav.classList.remove('text-light');
                mainBody.classList.add('content-white');
                //fpNav.addClass('text-light');
            } else if (destination.item.classList.contains('content-dark')) {
                mainBody.classList.remove('content-dark');
                mainBody.classList.remove('content-white');
                mainBody.classList.add('content-dark');
                //fpNav.addClass('text-dark');
            } else {
                mainBody.classList.remove("content-dark");
                mainBody.classList.remove("content-white");
            }
        },

        //afterRender: function(){},

        // eslint-disable-next-line no-unused-vars
        afterResize: function(width, height){
            // uncomment below to force reload windows on screen resize
            if (width > 1023) {
                // eslint-disable-next-line camelcase
                if (typeof fullpage_api !== 'undefined' && typeof fullpage_api.reBuild !== 'undefined') {
                    // eslint-disable-next-line no-console
                    console.log('reBuild')
                    // eslint-disable-next-line camelcase
                    fullpage_api.reBuild();
                }
            }
        },

        //afterReBuild: function(){},

        // eslint-disable-next-line no-unused-vars
        //afterResponsive: function(isResponsive){
            // eslint-disable-next-line no-console
            // console.log('isResponsive', isResponsive)
        //},

        // eslint-disable-next-line no-unused-vars
        //afterSlideLoad: function(section, origin, destination, direction, trigger){},

        // eslint-disable-next-line no-unused-vars
        onSlideLeave: function(section, origin, destination, direction, trigger){
            if (!document.body.classList.contains('scrolled')) {
                document.body.classList.add('scrolled');
            }

            if (document.body.classList.contains('scrolled')) {
                document.body.classList.remove('scrolled');
            }

            if (pageHeader !== null) {
                if (direction === 'right') { // hide
                    if (!pageHeader.classList.contains('hide-header')) {
                        pageHeader.classList.add('hide-header');
                    }
                } else if (direction === 'left') { // show
                    if (pageHeader.classList.contains('hide-header')) {
                        pageHeader.classList.remove('hide-header');
                    }
                }
            }

            destination.item.querySelectorAll('[data-anim-visible]').forEach(function(item){
                item.classList.add('anim-ready');
            });
        },

        // eslint-disable-next-line no-unused-vars
        //onScrollOverflow: function(section, slide, position, direction){}
    });

});
