// Import our custom CSS
import '../scss/main.scss'
import { Collapse } from 'bootstrap';
import ScrollAnim from './scroll-anim';
import './init-fullpage';
import './init-form';

// DOM fully loaded and parsed
// eslint-disable-next-line no-unused-vars
document.addEventListener("DOMContentLoaded", (event) => {
});

// page is fully loaded
window.addEventListener("load",  function () {

    // behavior when main menu is visible or hidden
    let menuCollapse = document.getElementById('main-menu');

    if (menuCollapse !== null) {

        // eslint-disable-next-line no-unused-vars
        menuCollapse.addEventListener('shown.bs.collapse', event => {
            document.body.classList.add('menu-visible');
        });

        // eslint-disable-next-line no-unused-vars
        menuCollapse.addEventListener('hidden.bs.collapse', event => {
            document.body.classList.remove('menu-visible');
        });

        let bsCollapse = new Collapse(menuCollapse, {
            toggle: false
        });

        //close menu when a link is clicked fo example
        [].forEach.call(document.querySelectorAll('#main-menu a:not(.dropdown-toggle)'), function (el) {
            // eslint-disable-next-line no-unused-vars
            el.addEventListener('click', function (event) {
                bsCollapse.hide();
            });
        });
    }

    // Scroll animation
    try {
        const scrollAnim = new ScrollAnim('.scroll-anim');
        scrollAnim.init();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('scroll-anim not available')
    }

    // document loaded, all script init executed, so hide loading screen
    let pageLoader = document.getElementById('page-loader');

    if (pageLoader != null) {
        pageLoader.classList.add('p-hidden');
    }

    document.body.classList.add('page-loaded');
});
