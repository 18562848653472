import axios from "axios"
window.axios = axios;

(function() {
    // Message form
    let msgModal = document.getElementById("messageModal")
    let messageForm = document.getElementById("contact-form")

    msgModal.addEventListener("hidden.bs.modal", function() {
        // eslint-disable-next-line no-console
        // console.log("modal closed")
        messageForm.querySelector(".form-text-feedback").innerHTML = ""
        messageForm.reset()
        messageForm.classList.remove("form-success")
        messageForm.classList.remove("form-error")
        messageForm.classList.remove("was-validated")
    })

    if (msgModal !== null && messageForm !== null) {
        messageForm.addEventListener("submit", function(event) {
            event.preventDefault()
            event.stopPropagation()
            if (!messageForm.checkValidity()) {
                messageForm.classList.add("was-validated")
            } else {

                //noinspection JSUnresolvedVariable
                grecaptcha.ready(function() { // eslint-disable-line
                    // noinspection JSUnresolvedVariable
                    grecaptcha.execute("6LfO8mgmAAAAAF_MTGw54FnzdDlkB-96HXe1kueU", { action: "submit" }).then(function(token) { // eslint-disable-line
                        document.getElementById("g-recaptcha-response").value = token

                        // setting up the server API
                        let server = messageForm.getAttribute("action")

                        if (!server) {
                            // You can replace it with your default server
                            server = "/ajax/sendmail.php"
                        }

                        // Get form input data
                        let name = messageForm.querySelector("#full-name").value
                        let email = messageForm.querySelector("#email-address").value
                        let message = messageForm.querySelector("#message").value
                        // Optional : you can add an email verification system here

                        let content = {
                            "name": name,
                            "email": email,
                            "message": message,
                            "g-recaptcha-response": token,
                            "submit": "submit"
                        }

                        _sendMessage(content, server)

                    })
                })
            }
        }, false)
    }

    function _sendMessage(content, server) {
        axios.post(server, content)
            .then(response => {
                let responseData = response.data
                // eslint-disable-next-line no-console
                // console.log(responseData)
                if (!("error" in responseData)) {
                    // messageForm.querySelectorAll('input, textarea').forEach(function (inputElement) {
                    //     inputElement.value = '';
                    // });

                    messageForm.reset()
                    messageForm.classList.remove("was-validated")
                    messageForm.classList.add("form-success")
                    messageForm.querySelector(".success-show").innerHTML = responseData.success
                } else {
                    messageForm.classList.add("form-error")
                    messageForm.querySelector(".error-show").innerHTML = responseData.error
                }
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error(error)
                messageForm.classList.add("form-error")
                // notify user about the error here
                messageForm.querySelector(".error-show").innerHTML = "Something went wrong."
            })
    }

})()
